<template>
    <div class="page-body wrapper-fixed">

        <div class="login-control d-flex">
            <router-link :to="{name : 'login'}" class="back-button">
                <div class="back-button__icon">
                    <i class="eicon e-back"></i>
                </div>
                <div class="back-button__text text-capitalize">{{ $t("back to login")}}</div>
            </router-link>
            <language-switcher class="ml-auto"></language-switcher>
        </div>
        <div class="col-md-6 login-thumbnail">
            <a href="#" class="site-logo">
                <site-logo-s-v-g/>
            </a>
            <img src="/img/Repeat Grid 3.png" alt="" class="login-page__background"/>
        </div>
        <div class="col-md-6 login-content">
            <div class="text-center">
                <h4 class="page-title">{{ $t("Reset Password")}}</h4>
            </div>
            <div class="login-box">
                <form action="">
                    <div class="form-group">
                        <label>{{ $t("Password")}}</label>
                        <input class="form-control" :class="{'has-error' : errors.password}" v-model="form.password"
                               type="password" :placeholder="$t('Enter your new password')" />
                        <error-message :message="errors.password" />
                    </div>
                    <div class="form-group">
                        <label>{{ $t("Confirm Password")}}</label>
                        <input class="form-control" :class="{'has-error' : errors.password_confirmation}"
                               v-model="form.password_confirmation" type="password"
                               :placeholder="$t('confirm your new password')" />
                        <error-message :message="errors.password_confirmation" />
                    </div>
                    <submit-button :click="submitNewPassword" :block="true" :loading="isLoading" style="width: unset!important;" >
                        {{ $t("Reset Password")}}
                    </submit-button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import SiteLogoSVG from "../components/SiteLogoSVG";
    const LanguageSwitcher = () => import("../components/dropdown/LanguageSwitcher.vue");
    import client from "../app/api/Client";
    const ErrorMessage = () => import("../components/common/ErrorMessage.vue");

    export default {
        components: {
            SiteLogoSVG,
            ErrorMessage,
            LanguageSwitcher,
        },
        data() {
            return {
                form: {
                    password: "",
                    password_confirmation: "",
                    email: "",
                    token: "",
                },
                errors: {
                    password: "",
                    password_confirmation: "",

                },
                isLoading: false
            }
        },
        computed:{
          token(){
              return this.$route.params.token;
          },
          email(){
             return this.$route.query.email;
          }
        },
        methods: {
            submitNewPassword() {
                this.form.email = this.email;
                this.form.token = this.token;
                if (this.validateForm()) {
                    this.isLoading = true;
                    client().post(`/password/reset`, this.form)
                        .then(({data: {message}}) => {
                            this.$toast.success(message);
                            this.isLoading = false;
                            this.form.password ='';
                            this.form.password_confirmation ='';
                            this.$router.push({name: 'login'});
                        })
                        .catch(error => {
                            let errors = error.response.data.message;
                            if (typeof errors === 'object') {
                                this.errors = _helper.serializeValidationMessage(errors);
                            } else {
                                this.alertError = true;
                                this.errorMessage = errors;
                                this.$toast.error(errors);
                            }
                            this.isLoading = false;
                        });
                }
            },
            validateForm() {
                this.errors.password = "";
                this.errors.password_confirmation = "";
                if (!this.form.email) {
                    this.$toast.error(this.$t('invalid request'));
                    return false;
                }
                if (!this.form.token) {
                    this.$toast.error(this.$t('invalid request'));
                    return false;
                }
                if (!_helper.validateEmail(this.form.email)) {
                    this.$toast.error(this.$t('invalid request'));
                    return false;
                }

                if (this.form.password.length < 1) {
                    this.errors.password = this.$t('password field is required');
                    return false;
                }

                if (this.form.password.length < 8) {
                    this.errors.password = this.$t('password should be more then 8 latter');
                    return false;
                }
                if (this.form.password_confirmation.length < 1) {
                    this.errors.password_confirmation = this.$t('confirm password field is required');
                    return false;
                }

                if (this.form.password.length < 8) {
                    this.errors.password_confirmation = this.$t('password should be more then 8 latter');
                    return false;
                }

                if (this.form.password !== this.form.password_confirmation) {
                    this.errors.password_confirmation = this.$t('password does not match');
                    return false;
                }

                return true;
            }
        }
    }
</script>

